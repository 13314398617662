<template>
  <Page class="py-8">
    <h2 class="w-full">
      {{ l('To get started creating an estimate, please select the options provided below') }}
    </h2>
    <p class="mb-4 w-full max-w-[1000px]">
      {{
        l(
          `Simply choose one of the options below that best match your needs, and we'll instantly generate a shoppable estimate. You'll be redirected to the estimate, where you can make upgrades, refine your selections, and submit a request for proposal to the contractor with just one click.`
        )
      }}
    </p>
    <OpenQuotes :companyId="companyId" :leadFormId="companyData.lead_rotation_id" />
  </Page>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import useTranslation from '@/components/composables/Translation'
import useForm from '@/components/forms/Form'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import OpenQuotes from '@/components/openQuote/OpenQuotes.vue'

const companyData = ref({})
const companyExists = ref(true)
const loading = ref(true)
const route = useRouter()

const store = useStore()
const { fetchLeadForm } = useForm()

const companyId = computed(() => route.currentRoute.value.params.companyId)

const { setLocalization, l } = useTranslation()

const fetchCompanyData = async () => {
  loading.value = true
  try {
    const { payload } = await store.dispatch('ajax', {
      path: `/api/${import.meta.env.VITE_LEAD_FORM_KEY}/external/companyBio`,
      data: { company_id: companyId.value }
    })

    if (!payload || !payload.company_name) {
      companyExists.value = false
      return
    }

    const {
      localizationLanguageCode,
      localizationCountryCode,
      company_logo_file_id: companyLogoFileId
    } = payload

    setLocalization(localizationLanguageCode, localizationCountryCode)

    companyData.value = {
      ...payload,
      ...(companyLogoFileId
        ? { companyLogo: `${import.meta.env.VITE_BASE_FILE_URL}file/view/${companyLogoFileId}` }
        : {})
    }
  } catch (e) {
    companyExists.value = false
  }

  loading.value = false
}

onMounted(async () => {
  await fetchCompanyData()
  if (companyData.value.lead_rotation_id) fetchLeadForm(companyData.value.lead_rotation_id)
})
</script>
